@import "src/styles/mixins";

.cookie-popup {
    position: fixed;

    z-index: 100;
    background: var(--background);
    box-shadow: 0px -2px 14px 2px rgba(0, 0, 0, 0.1);
    bottom: 0;
    right: 0;
    width: 100%;
    @include mq('tablet-wide') {
        bottom: 20px;
        right: 20px;
        max-width: 780px;
        border-radius: 15px;
    }


    .container {
        align-items: center;
        justify-content: center;
        padding: 20px 15px;
        display: flex;
        font-size: 1rem;
        line-height: 1.5rem; 
        flex-wrap: wrap;
        grid-gap: 20px;
        @include mq('tablet') {
            padding: 20px;
            width: auto;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 30px;
        }

        img {
            flex-basis: 40px;
            @include mq('tablet') {
                flex-basis: auto;
            }
        }

        span {
            flex-basis: calc(100% - 60px);
            @include mq('tablet') {
                flex-basis: auto;
            }
        }

        button {
            width: 100%;
            @include mq('tablet') {
                max-width: 200px;
            }
        }
    }
}