.info-icon {
    cursor: pointer;
    position: relative;
    z-index: 10;

    .button {
        padding: 0;
        width: 28px;
        height: 28px;
        background: #FFF;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), inset 0px 2px 6px rgba(200, 194, 194, 0.08);
        display: flex;
        border-radius: 50%;
        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), inset 0px 2px 6px rgba(200, 194, 194, 0.08);
        }

        .icon {
            margin: auto;
            width: 22px;
            height: 22px;
        }
    }

    .text {
        background: #FFF;
        position: absolute;
        top: calc(100% + 8px);
        right: -8px;
        padding: 10px 12px;
        min-width: 270px;
        border-radius: 5px;
        box-shadow: 0 2px 14px 2px rgba(0, 0, 0, 0.1);
        user-select: none;
        font-size: 14px;

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #FFF;
            position: absolute;
            top: -5px;
            right: 16px;
        }
    }
}