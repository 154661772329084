@import "src/styles/mixins";


.currency-selector {
    position: absolute;
    z-index: 11;
    top: 14px;
    left: 20px;

    .button {
        width: 36px;
        height: 36px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), inset 0 2px 6px rgba(200, 194, 194, 0.08);
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-size: 16px;
        font-weight: bold;
        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), inset 0 2px 6px rgba(200, 194, 194, 0.08);
        }
    }

    .drop-down {
        background: #FFF;
        box-shadow: 0 2px 14px 2px rgba(0, 0, 0, 0.1);
        margin-top: 12px;
        border-radius: 5px;
        position: relative;
        padding: 5px 0;

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #FFF;
            position: absolute;
            top: -5px;
            transform: translateX(-50%);
            left: 50%;
        }
        
        .option {
            font-size: 14px;
            padding: 8px 0;
            position: relative;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            color: rgb(#333, 0.4);

            &:hover {
                &::after {
                    opacity: 0.2;
                }
            }
        }

        .option::after {
            content: '';
            background: #5f9ea0;
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .option.__selected {
            opacity: 1;
            color: #5f9ea0;
            font-weight: bold;
        }
    }
}