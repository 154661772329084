.alert-popup {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 12px 14px 12px 40px;
    z-index: 1000;
    box-shadow: 0 2px 14px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background: white;
    font-size: 14px;
    max-width: 90vw;
    color: #fff;

    &.__warning {
        background: #fb8c00;
    }

    &.__success {
        background: #4CAF50;
    }

    span {
        font-weight: bold;
        font-size: 18px;
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
    }
}