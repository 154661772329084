.close-icon {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border: 2px solid var(--primary);
    border-radius: 50%;
    background: transparent;
    position: relative;

    > span {
        color: var(--primary);
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        line-height: 24px;
    }
}