.input-field {
    position: relative;
    font-size: 14px;
    display: flex;
    width: 100%;

    > .prefix {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 400;
        user-select: none;
    }

    > .input {
        padding: 8px 60px 8px 25px;
        gap: 10px;
        width: 100%;
        font-weight: 400;
        font-size: inherit;
        letter-spacing: 0.02em;
        color: #333333;
        // background: rgba(0, 0, 0, 0.05);
        // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
        // border-radius: 15px;
        height: auto;

        &::placeholder {
            color: rgba(0, 0, 0, 0.4);
            opacity: 1;
        }
    }

    > .label {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        padding: 4px 10px;
        font-weight: 700;
        font-size: 10px;
        color: #FFFFFF;
        background: var(--primary);
        transition: background 15s ease-in-out;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        text-align: center;
        user-select: none;
        width: fit-content;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::placeholder {
            color: white;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
