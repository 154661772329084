@import "src/styles/mixins";

.result-box {
    padding: 10px;
    gap: 10px;
    width: 100%;
    background: rgba(255, 255, 255, 0.22);
    box-shadow: 0 2px 14px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-width: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    .label {
        padding: 4px 10px;
        gap: 10px;
        background: var(--primary);
        transition: background 15s ease-in-out;
        border-radius: 15px;
        font-weight: 700;
        font-size: 10px;
        color: #FFFFFF;
        width: fit-content;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    }

    .amount {
        font-weight: 700;
        font-size: 34px;
        color: #333333;
        width: 100%;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .text {
        font-size: 16px;
        text-transform: capitalize;
        color: #333333;
        width: fit-content;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .percentage {
        padding: 2px 0;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--primary);
        border: 2px solid var(--primary);
        transition: all 15s ease-in-out;
        width: 60px;
    }
}

.result-box.__small {
    .amount {
        font-size: 38px;
    }

    @include mq('phone') {
        flex-basis: 49%;

        .amount {
            font-size: 24px;
        }

        .text {
            font-size: 14px;
        }

        .percentage {
            padding: 2px 6px;
            font-size: 12px;
        }
    }
}