@import "src/styles/mixins";

html {
    background: ghostwhite;
}

.App {
    min-height: 100vh;
    background: var(--background);
    transition: background 15s ease-in-out;
    font-weight: 300;

    .app-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 800px;
        @include mq('tablet-wide') {
            display: grid;
            align-items: flex-start;
            grid-template-columns: 1fr 2fr;

            > .default-button {
                grid-column: span 2;
                max-width: 400px;
                margin: auto;
                width: 100%;
            }
        }

        .header-section {
            @include mq('tablet') {
                grid-column: span 2;
            }
        }

        .main-section {
            grid-row-gap: 20px;
            display: flex;
            flex-direction: column;
            @include mq('tablet-small') {
                flex-direction: row;
                grid-column-gap: 10px;
                grid-column: span 2;
            }
        }

        .results-section {
            grid-column: span 2;
        }

        .sub-section {
            text-align: left;
            grid-column: span 2;
            display: flex;
            flex-direction: column;
            grid-row-gap: 10px;
            &:last-of-type {
                margin-bottom: 40px;
            }

            h3 {
                margin-bottom: 0;
            }
        }
    }
}

.results-box {
    position: relative;
    background: transparent;

    .container {
        .close-button {
            display: none;
        }

        .title:not(:last-of-type),
        .card-row:not(:last-of-type) {
            display: none;
        }

        .card-row {
            justify-content: stretch;
            @include mq('tablet-small') {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            @include mq('tablet') {
                grid-auto-flow: column;
                grid-template-columns: auto;
                .result-box:nth-of-type(3n + 1) {
                    grid-column: 1
                }
                .result-box:nth-of-type(3n + 2) {
                    grid-column: 2
                }
                .result-box:nth-of-type(3n + 3) {
                    grid-column: 3
                }
            }
        }
    }
}

.results-box.__popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 10px;
    display: flex;
    z-index: 100;
    overflow: scroll;
    @include mq('phone') {
        padding: 10px 15px;
    }

    .container {
        position: relative;
        margin: auto;
        width: 100%;
        z-index: 100;
        background: var(--background);
        transition: 15s ease-in-out;
        border-radius: 15px;
        max-width: 480px;

        .close-button {
            display: block;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 5px;
            line-height: 32px;
            font-size: 30px;
            z-index: 1;
            font-weight: bold;
            background-color: #8a8a8a;
            color: transparent;
            text-shadow: 0 3px 4px rgb(255 254 254 / 70%);
            background-clip: text;
            transition: text-shadow 0.1s ease;
            &:hover {
                text-shadow: 0 2px 3px rgb(255 254 254 / 50%);
            }
        }

        .card-row {
            gap: 10px 2%;
            flex-wrap: wrap;

            .input-field {
                @include mq('phone-wide') {
                    flex-basis: 49%;
                }
            }
        }

        .title,
        .card-row {
            display: flex;
        }

        .title:not(:first-of-type) {
            margin-top: 12px;
        }
    }

    .background {
        background: rgba(0,0,0,0.3);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

footer {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    padding: 20px 20px 40px;
    margin: auto;


    .copyright {
        font-size: 12px;
        text-align: left;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        grid-column-gap: 10px;

        li {
            margin-left: 10px;
            font-weight: bold;
            font-size: 12px;
            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }
}

.privacy-page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    margin: auto;
}
