.default-card {
    background: white;
    border-radius: 15px;
    padding: 14px 16px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: fit-content;

    .title {
        font-weight: 700;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        text-align: left;
        margin-bottom: 4px;
    }

    .card-row {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
    }

    .default-button {
        margin-top: 10px;
    }

    .info-icon {
        position: absolute;
        top: 14px;
        right: 16px;
    }
}